import { CorePx } from '@fanduel/core-px';
import { CoreConfig } from '@fanduel/core-config';
import CoreDeepLinks from '@fanduel/core-deeplinks';
import { getRegion } from '../common/helpers/cookies';
import { getBrand, getCountry, getEnvironment } from '../common/helpers/unified-modules';
import { initializeAccountUM, subscribeSession } from './account-library';
import { getDecodedClientID } from './utils';
import { ModalPresenter } from '@fanduel/core-modal-presenter';
import { CoreApiIdentities } from '@fanduel/core-api-identities';
import { Config, Product } from '@fanduel/core-config-contract';
import { CoreWebViewPluginRegistry } from '@fanduel/core-webview';
import config from '../../config/config';

let initialized = false;

export const initializeUnifiedModules = async () => {
        if (initialized) {
            console.warn("Unified modules already initialized.");
            return;
        }

        CoreWebViewPluginRegistry.add({
            id: 'show-header',
            onUrlLoaded: (ref) => {
                ref.updateConfig((conf) => ({
                    ...conf, 
                    showHeader: true,
                    showFooter: true
                }))
            }
        })
        
        const decodedClientID = getDecodedClientID(config.clientID);

        const conf: Config = {
            appDomain: {
                product: Product.Casino,
                region: getRegion()?.toLowerCase?.(),
                brand: getBrand(process.env.BRAND)
            },
            clientAuthToken: decodedClientID,
            environment: {
                type: getEnvironment(),
                country: getCountry(process.env.COUNTRY)
            }
        }

        await CoreConfig.setConfig(conf);
        await CorePx.initialize();
        await CoreDeepLinks.initialize();
        await initializeAccountUM();
        CoreApiIdentities.initialize();
        ModalPresenter.initialize();

        await subscribeSession();
        initialized = true;
        return true;
 };
