import { checkValidSession, clearSession, getSessionUM, getLoginToken } from '../../../unified-modules/account-library';
import { getAccountLogger } from '../../../unified-modules/account-library-logger';
import { AW_COOKIE, MESSAGES } from '../../constants';
import { getAWCookies, removeAWCookies, setAWCookies } from '../../helpers/cookies';
import { loginRedirect } from '../../helpers/loginRedirect';
import {
    sendIosSessionExpiredMessage,
    isRunningInsideV3Wrapper,
    isRunningInsideWrapper,
    sendWrapperEvent,
    sendIosTokenRefreshMessage
} from '../../wrapper-bridge-mobile';

export const sessionExpirationRequestInterceptor = axiosInstance => {
    const logger = getAccountLogger(true);
    const interceptor = axiosInstance.interceptors.response.use(
        requestResponse => requestResponse,
        async requestError => {
            const status = requestError?.response?.status;
            if (status !== 401) {
                return Promise.reject(requestError);
            }

            const validSession = await checkValidSession();
            const loginToken = getAWCookies()?.loginToken;
            axiosInstance.interceptors.response.eject(interceptor);

            if (validSession && loginToken) {
                const session = await getSessionUM(true);
                const authToken = session.token;
                const loginToken = await getLoginToken();

                if (session) {
                    axiosInstance.defaults.headers[AW_COOKIE.AUTH_TOKEN] = session.token;
                    requestError.response.config.headers[AW_COOKIE.AUTH_TOKEN] = session.token;

                    sendIosTokenRefreshMessage({
                        authToken,
                        loginToken
                    });

                    logger.debug(`Syncroninzing session after token refresh`);

                    setAWCookies(authToken, loginToken);

                    return axiosInstance(requestError.response.config);
                }
            }

            if (isRunningInsideWrapper() || isRunningInsideV3Wrapper()) {
                removeAWCookies();
                clearSession();
                sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
                sendIosSessionExpiredMessage();
            } else {
                const hadActiveSession = !!sessionStorage.getItem('hadActiveSession');
                if (hadActiveSession) {
                    loginRedirect();
                }
            }

            // Re-inject the response interceptor
            sessionExpirationRequestInterceptor(axiosInstance);
        }
    );
};
