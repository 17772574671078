import config from '../../../../config/config'
import { substituteJsonPlaceholders } from '../../helpers/jsonPlaceholders'
import { useSelector } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { isLoggedIn } from '../../helpers'
import { getSessionUM } from '../../../unified-modules/account-library'
import { getUserId } from '../../helpers/get-data-unified-modules'
import { isRunningInsideWrapper } from '../../wrapper-bridge-mobile'
import { getAWCookies, getRegion } from '../../helpers/cookies'
import { getAccountLogger } from '../../../unified-modules/account-library-logger'

const useAuthenticated = async () => {
    const logger = getAccountLogger(true)
    let userId = useSelector(state => state?.session?.userId)
    const sessionId = useSelector(state => state?.session?.sessionId)
    const playerGuid = useSelector(state => state?.GANLogin?.ganToken?.playerGuid)
    const isAndroidWrapper = isRunningInsideWrapper()

    const loggedIn = await isLoggedIn()
    const loginToken = getAWCookies()?.loginToken;
    logger.debug(`Checking login status: ${loggedIn}`)

    if (loggedIn && loginToken) {
        // TODO user===true what is this, refactor this
        if (!userId || userId === true){
            userId = await getUserId()
        }

        sessionStorage.setItem('hadActiveSession', 'true')
        datadogRum.setUser({
            id: userId,//externalPlayerRef
            sessionId: sessionId || 'undefined',
            playerGuid: playerGuid || 'undefined'
        })
    } else {
        datadogRum.addAction('[A&W] REDIRECTED_TO_LOGIN')

        if (isAndroidWrapper){
            const variables = {
                region: getRegion()?.toUpperCase(),
                externalReferrerNext: encodeURIComponent(window.location.search)
            }
            const loginURL = substituteJsonPlaceholders(config['loginURL'], variables)
            window.location = loginURL
            return
        }
        const session = await getSessionUM()

        if (session) {
            sessionStorage.setItem('hadActiveSession', 'true')
            window.location.reload()
        }
    }
}

export default useAuthenticated
