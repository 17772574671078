import { Account } from '@fanduel/account';
import { getAWCookies, setAWCookies } from '../common/helpers/cookies';
import { initializeUnifiedModules } from './unified-modules';
import { loginRedirect } from '../common/helpers/loginRedirect';
import { getAccountLogger } from './account-library-logger';

export const initializeAccountUM = () => {
    return Account.initialize();
};

let initialize: any = null;
let isInitializingPromise: Promise<any> | null = null;

export const syncCookiesUM = async session => {
    const logger = getAccountLogger(true);
    const authToken = session.token;
    const loginToken = await Account.getLoginTokenAsync();

    logger.debug(`Syncroninzing session after token refresh in Account -library`);

    setAWCookies(authToken, loginToken);
};

export const initializeUM = async () => {
    if (initialize) {
        return Promise.resolve(initialize);
    }

    if (isInitializingPromise) {
        await isInitializingPromise;
        return initialize;
    }

    isInitializingPromise = (async () => {
        try {
            initialize = await initializeUnifiedModules();
            return initialize;
        } finally {
            isInitializingPromise = null;
        }
    })();

    return isInitializingPromise;
};

export const subscribeSession = async () => {
    return await Account.subscribeSession(session => {
        const hadActiveSession = !!sessionStorage.getItem('hadActiveSession');

        if (!session && hadActiveSession) {
            sessionStorage.removeItem('hadActiveSession');
            loginRedirect();
        }

        if (session && hadActiveSession) {
            syncCookiesUM(session);
        }
    });
};

export const getSessionUM = async (useSilent?: boolean) => {
    try {
        await initializeUM();
        if (useSilent) {
            return await Account.getSessionAsync('Silent');
        }
        return Account.getSessionAsync('Normal');
    } catch (err) {
        console.log(err);
    }
};

/*
@deprecated
*/
export const getLoginToken = async () => {
    await initializeUM();
    return Account.getLoginTokenAsync();
};

export const getUserDataUM = async () => {
    try {
        await initializeUM();
        const { loginToken } = getAWCookies();
        return await Account.getUserAsync({ loginToken });
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const checkValidSession = async () => {
    await initializeUM();

   return await Account.checkValidSession();
};

export const clearSession = () => {
    return Account.clearSession();
};

export const keepAlive = () => {
    return Account.keepAlive();
};

export const keepAliveFlow = async () => {
    const sessionUM = await getSessionUM();

    if (!sessionUM) {
        loginRedirect();
        return;
    }
    try {
        keepAlive();
    } catch (error) {
        console.log(error);
    }
};

export const getPriorSession = () => {
    return Account.getPriorSession();
};
